<template>
    <div>
        <div class="section section-lg mt-4" id="hideMobile">
            <div class="container">
                <div class="d-flex align-items-center justify-content-center mt-5">
                    <img src="assets/images/logo.png" style="width: 80px;" class="mx-2">
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                        <h1 class="font-weight-bolder text-primary mb-3 mt-3 title-lg mb-4">Tentang Kami</h1>
                        <p>
                            Gubuk sains prima adalah portal yang menyediakan berbagai jenis kegiatan, seperti belajar
                            khusus
                            hingga kompetisi online. Kegiatan belajar khusus yaitu dengan menyediakan latihan dan
                            simulasi pengerjaan soal-soal kedinasan, PPPK, dan CPNS, sedangkan kompetisi online terdiri
                            atas olimpiade (SOSHUM-SAINTEK), lomba menulis esai dan lomba karya tulis ilmiah (LKTI)
                            untuks SMP sampai Mahasiswa pada tingkat nasional.
                        </p>
                        <hr class="my-4">
                        <div class="px-3 py-4 bg-light rounded text-left shadow-sm mb-5">
                            <div class="font-weight-bolder text-dark mb-3">Visi : </div>
                            <p class="mb-0 text-sm">Menjadi platform pelatihan dan kompetisi yang kompetitif dan
                                inovatif dengan sistem online yang mengedepankan pelayanan yang prima.</p>
                            <div class="font-weight-bolder text-dark mb-3 mt-4">Misi : </div>
                            <p class="mb-0 text-sm">
                                <ol>
                                    <li>Membangun platform Gubuk Sains Prima dengan sistem online dan terintegrasi ke
                                        semua program sehingga memudahkan para users untuk mencari dan mendapatkan
                                        informasi yang jelas dan komprehensif.</li>
                                    <li>Menyelenggarakan pelatihan dan kompetisi online dengan beberapa program untuk
                                        melatih dan mempersiapkan diri peserta dalam menghadapi ujian seleksi masuk dan
                                        ujian menjadi ASN.</li>
                                    <li>Menjaga kepercayaan dan hubungan yang harmonis kepada semua pihak (users dan
                                        partners) dalam berinteraksi dan menggunakan platform Gubuk Sains Prima.</li>
                                </ol>
                            </p>
                        </div>
                        <div class="title-md mb-3">Legalitas Kami Kami</div>
                        <a href="https://s.id/AHUgubuksainsprima" target="blank">
                            <img src="assets/images/legalitas.jpeg" class="legalitas-img">
                        </a>
                        <br />
                        <span>Cek Legalitas : <a href="https://s.id/AHUgubuksainsprima"
                                target="blank">https://s.id/AHUgubuksainsprima</a></span>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'About',
        metaInfo: {
            title: 'Gubuk Sains Prima',
            titleTemplate: '%s — Tentang Kami'
        },
    }
</script>