<template>
    <div>
        <div class="section section-xl card-style-bottom-left card-3d-bottom bg-lighter bg-image"
            style="background-image: url('assets/images/blank-background.png');margin-top:42px">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-12 text-center-sm wow fadeInDown" data-wow-duration="1s">
                        <div class="font-weight-bolder text-xlg mb-md-4"><b>{{name}}</b></div>
                        <hr />
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12 pr-md-1 mb-md-0 mb-5 wow fadeInLeft"
                        data-wow-duration="1s">
                        <img :src="cover" class="video-box rounded shadow w-100" />
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12 text-center-sm wow fadeInRight" data-wow-duration="1s">
                        <p class="mb-4 ml-3 mt-5">
                            {{description}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="container wow fadeInUp mt-5" data-wow-duration="1s">
                <hr />
                <br />
                <div class="d-block">
                    <div class="title-lg d-block text-center mb-5">
                        <span class="text-primary font-weight-bold">Benefit</span> Mengikuti
                        <span class="text-primary font-weight-bold"><u>{{name}}</u></span><br />bersama
                        <span class="text-primary font-weight-bold">Gubuk Sains Prima</span>
                    </div>
                    <div class="row row-w-line text-md">
                        <div class="col-w-line col-lg-3 col-md-12 col-sm-12 text-center-sm mb-4 mb-md-4 mt-4">
                            <b>1. Aplikasi tersedia di semua platform online.</b><br />
                            <hr />
                            Kamu bisa belajar tanpa kendala tempat, jarak dan waktu.
                        </div>

                        <div class="col-w-line col-lg-3 col-md-12 col-sm-12 text-center-sm mb-4 mb-md-4 mt-4">
                            <b>2. Akses Materi Belajar dan Kumpulan Soal</b><br />
                            <hr />
                            Kamu bisa memperkaya materi dan soal-soal yang diujikan.
                        </div>

                        <div class="col-w-line col-lg-3 col-md-12 col-sm-12 text-center-sm mb-4 mb-md-4 mt-4">
                            <b>3. Kemiripan Desain Modul Ujian</b><br />
                            <hr />
                            Kamu akan terbiasa dengan sistem tes berbasis komputer (CAT/UTBK).
                        </div>

                        <div class="col-w-line col-lg-3 col-md-12 col-sm-12 text-center-sm mb-4 mb-md-4 mt-4">
                            <b>4. Manajemen Waktu Pengerjaan</b><br />
                            <hr />
                            Kamu bisa berlatih penguasaan startegi optimasi waktu.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-block bg-primary wow fadeInUp" data-wow-duration="1s">
            <div class="py-4">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-8 mb-md-0 mb-3">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="h2 m-0 text-white">Daftar Sekarang</div>
                                    <div class="d-block text-white text-sm m-0">Ayo gabung dan menjadi bagian dari kami
                                        untuk pengalaman belajar yang
                                        lebih menarik!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-right"><a :href="frontend_url+'#/signup'"
                                class="btn btn-white shadow rounded px-4">Daftar
                                Gratis</a></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="dataDetail.length > 0" class="section section-lg card-style-top-right" data-wow-duration="1s"
            style="background-image: url('assets/images/blank-background.png');background-size: cover;">
            <div class="container-fluid wow fadeInUp" v-if="dataFetch">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-12 col-sm-12 text-center">
                        <p class="mb-4">Loading...</p>
                    </div>
                </div>
            </div>

            <div class="container-fluid wow fadeInUp" v-if="!dataFetch" >
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-12 col-sm-12 text-center">
                        <div class="title-lg font-weight-bolder mb-3">Paket <span class="text-primary">Program
                                {{code}}</span>
                        </div>
                        <hr />
                        <p class="mb-4">Terus latihan dan Uji kemampuanmu dengan TryOut dan soal - soal <span
                                class="text-primary"><b>program {{code}}</b></span> berkualitas sesuai standar yang
                            sebenarnya
                            dengan harga terjangkau.</p>
                    </div>
                </div>

                <div class="container">
                    <div class="row justify-content-center">
                        <div v-for="(item, index) in dataDetail" :key="index" class="col-lg-4 col-sm-12 col-md-12 col-sm-12 mt-4">
                            <div class="card border lift mb-0 h-100" style="overflow: hidden;">
                                <div class="card-body">
                                    <div v-if="item.favorite" class="ribbon ribbon-top-right"><span>Favorit <i class="fe fe-thumbs-up"></i>
                                        </span></div>
                                    <h1 class="mb-2 text-center">{{item.name}}</h1>
                                    <hr />
                                    <h1 v-if="item.price_out" class="mb-2 text-center text-muted"><s>{{item.text_price_out}}</s></h1>
                                    <h1 class="mb-4 text-center text-xlg"><b>{{item.text_price}}</b></h1>

                                    <table style="width: 100%;" v-if="item.description_other">
                                        <tr v-for="(itemDetail, indexDetail) in item.description_other" :key="indexDetail">
                                            <td style="vertical-align: top; width: 8%; color: #22a843  !important;"><i
                                                    class="fe fe-check"></i>
                                            </td>
                                            <td v-html="itemDetail"></td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="card-footer" style="margin-top: -25px;">
                                    <div class="d-block">
                                        <a :href="frontend_url+'#/signup'" target="_blank"
                                            class="btn btn-primary btn-block">Daftar
                                            Sekarang</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="dataDetail.length > 0" class="mt-5">
            <div class="card-style-top-right mb-5" data-wow-duration="1s">
                <div class="container-fluid">
                    <div class="row justify-content-center wow fadeInDown">
                        <div class="col-md-12 text-center col-sm-12">
                            <div class="title-lg font-weight-bolder mb-3">
                                Metode Pembayaran
                            </div>
                            <p class="mb-5">Tersedia beragam pilihan metode pembayaran</p>
                            <img src="assets/images/payment-method-gsprima.jpeg" style="width:80%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'About',
        metaInfo: {
            title: 'Gubuk Sains Prima',
            titleTemplate: '%s — Program Kami'
        },
        data() {
            return {
                code        : this.$route.params.code,
                name        : '',
                description : '',
                cover       : '',
                dataFetch   : true,
                dataDetail  : [],
            }
        },
        created() {
            this.getProgram();
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'general/program/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code : this.code,
                    }
                }).then(res => {
                    this.dataFetch  = false;
                    if(res.data.status) {
                        this.dataDetail = res.data.data;
                    }
                });
            },
            getProgram: function() {
                if(this.code == 'cpns') {
                    this.name = 'Program CPNS';
                    this.description = 'Singkatan dari Calon Pegawai Negeri Sipil. Merupakan program belajar yang intensif sebagai persiapan peserta dalam menghadapi ujian CPNS tahun 2022. Program ini menawarkan platform persiapan tes CPNS ter-up-to-date dengan metode pembelajaran semi-interaktif sehingga memastikan kepuasan dan kenyamanan peserta dalam belajar. Aplikasi ini menyediakan berbagai soal- soal bertipe HOTS, kuis harian, pembahasan, statistik progres belajar, tips dan trik, tryout mandiri hingga tryout bersama';
                    this.cover = 'assets/images/cover/cpns.jpg';
                }

                else if(this.code == 'kedinasan') {
                    this.name = 'Program Kedinasan';
                    this.description = 'Merupakan program belajar khusus dan fokus bagi para peserta yang akan mengikut seleksi masuk Sekolah Kedinasan seperti STAN, STIS, STIN, STMKG, SSN, IPDN, politeknik di berbagai kementrian dan lain sebagainya. Melalui program ini diharapkan setiap peserta mampu mengetahui seluruh materi dan tipe-tipe soal terbaru dalam ujian kedinasan dan belajar secara intensif.';
                    this.cover = 'assets/images/cover/kedinasan.jpg';
                }

                else if(this.code == 'pppk') {
                    this.name = 'Program PPPK';
                    this.description = 'Singkatan dari Pegawai Pemerintah dengan Perjanjian Kerja. Merupakan program belajar yang komprehensif untuk memastikan setiap peserta mampu mengerti dan memahami tentang materi dan soal-soal dalam ujian PPPK. Hal ini dikarenakan dalam program PPPK terdapat alur yang berbeda dari CPNS sehingga peserta mengetahui tentang potensi dirinya dan optimis lulus menjadi seorang PPPK.';
                    this.cover = 'assets/images/cover/pppk.jpg';
                }

                else if(this.code == 'olimpiade') {
                    this.name = 'Olimpiade';
                    this.description = 'Merupakan kompetisi online yang bekerjasama dengan Pelajar Olimpiade dalam menyelenggarakan kegiatan Olimpiade tingkat nasional dan telah berjalan sejak tahun 2019. Olimpiade dilaksanakan per individu untuk level SMA/MA/Sederajat. Kategori lomba teridiri dari Rumpun SAINTEK (Teknik, MIPA), Rumpun SAINS Kesehatan (Kedokteran, Farmasi) dan Rumpun SOSHUM (Sosial Budaya, Ilmu Politik, dan bahasa).';
                    this.cover = 'assets/images/cover/olimpiade.png';
                }

                else if(this.code == 'esai') {
                    this.name = 'Esai';
                    this.description = 'Merupakan kompetisi menulis individu (bekerjasama dengan Pelajar Olimpiade) yang membahas suatu masalah atau isu yang sedang hangat di tengah masyarakat. Esai dapat berupa opini, pandangan atau ekspresi pribadi dari penulis yang disertai dengan sumber atau referensi yang valid atau dapat dipercaya. Kompetisi ini diselenggarakan untuk peserta dari tingkat SMP/SMA hingga mahasiswa dan umum.';
                    this.cover = 'assets/images/cover/esai.png';
                }

                else if(this.code == 'lkti') {
                    this.name = 'LKTI';
                    this.description = 'Singkatan dari Lomba Karya Tulis Ilmiah. Merupakan Lomba menulis karya tulis ilmiah tingkat nasional per kelompok (bekerjasama dengan Pelajar Olimpiade) yang dapat diikuti oleh peserta dari SMP/SMA/Sederajat sampai dengan Mahasiswa dan Umum. Setiap kelompok diberikan kebebasan dalam memilih subtema atau topic yang ingin diteliti dan menjadi sebuah karya tulis ilmiah.';
                    this.cover = 'assets/images/cover/lkti.png';
                }
            }
        }
    }
</script>