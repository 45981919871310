import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Program from '../views/Program.vue'
import Maintenance from '../views/Maintenance.vue'
import Notfound from '../views/Notfound.vue'
import TermConditions from '../views/TermConditions.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import MainMenu from '../layout/MainMenu.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout : MainMenu,
      transition: 'fade'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      layout : MainMenu,
      transition: 'fade'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      layout : MainMenu,
      transition: 'fade'
    }
  },
  {
    path: '/program/:code',
    name: 'program',
    component: Program,
    meta: {
      layout : MainMenu,
      transition: 'fade'
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: Notfound,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/term-conditions',
    name: 'term_conditions',
    component: TermConditions,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: PrivacyPolicy,
    meta: {
      transition: 'fade'
    }
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }  
})

export default router