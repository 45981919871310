<template>
    <div>
        <div class="section section-lg mt-4" id="hideMobile">
            <div class="container">
                <div class="d-flex align-items-center justify-content-center mt-5">
                    <img src="assets/images/logo.png" style="width: 80px;" class="mx-2">
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8 px-lg-5">
                        <h1 class="text-center font-weight-bolder text-primary mb-3 mt-3 title-lg mb-4">Kontak Kami</h1>
                        <p class="mb-3 font-weight-bolder">
                            CV GUBUK SAINS PRIMA<br/>
                            Athaya Ville Blok A-15, Jalan Raya Puri, Kel. Puri, Kec. Puri, Kab. Mojokerto, Provinsi
                            Jawa Timur, 61363
                        </p>
                        <hr>
                        <div class="d-block mb-3">
                            <div class="d-flex text-sm font-500 font-weight-bolder"><i class="fe fe-mail mr-2"></i> Alamat Email : </div>
                            <div>
                                <a href="mailto:cv.gubuksainsprima@gmail.com" target="blank">cv.gubuksainsprima@gmail.com</a>
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <div class="d-flex text-sm font-500 font-weight-bolder"><i class="fe fe-phone mr-2"></i> Telp/HP/WA : </div>
                            <div class="content text-sm"><a href="https://web.whatsapp.com/send?phone=6281273068280&text=halo,%20saya%20ingin%20bertanya%20mengenai%20gubuk%20sains%20prima" target="_blank">+6281273068280</a></div>
                        </div>
                        <hr>

                        <div class="mapouter">
                            <div class="gmap_canvas"><iframe width="757" height="500" id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=Athaya%20Ville%20Blok%20A-15,%20Jalan%20Raya%20Puri,%20Kel.%20Puri,%20Kec.%20Puri,%20Kab.%20Mojokerto,%20Provinsi%20Jawa%20Timur,%2061363&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Contact',
        metaInfo: {
            title: 'Gubuk Sains Prima',
            titleTemplate: '%s — Kontak Kami'
        },
    }
</script>