<template>
    <div>
        <!-- Main Navigation -->
        <div class="main-navigation shadow">
            <div class="container h-100 px-lg-5">
                <div class="main-navigation--inner">
                    <div class="main-logo hide-991">
                        <router-link :to="{name : 'home'}" @click.native="setCurrent('home')">
                            <img src="assets/images/logo-long.png" alt="GubukSains Logo" style="width:80%">
                        </router-link>
                    </div>

                    <div class="main-logo show-991" style="width:20%">
                        <router-link :to="{name : 'home'}" class="close-link" @click.native="setCurrent('home')">
                            <img class="logo-mobile" src="assets/images/logo.png" alt="GubukSains Logo">
                        </router-link>
                    </div>

                    <div class="main-nav">
                        <ul class="navbar-nav horizontal mr-3 nav-black navbar-style-2 text-center-md">
                            <li class="nav-item desktop-only">
                                <router-link :to="{name : 'home'}" class="nav-link" :class="activeClass == 'home' ? 'active-link':''" @click.native="setCurrent('home')">Beranda</router-link>
                            </li>
                            <li class="nav-item desktop-only">
                                <router-link :to="{name : 'about'}" class="nav-link" :class="activeClass == 'about' ? 'active-link':''" @click.native="setCurrent('about')">Tentang Kami</router-link>
                            </li>
                            <li class="nav-item desktop-only">
                                <router-link :to="{name : 'contact'}" class="nav-link" :class="activeClass == 'contact' ? 'active-link':''" @click.native="setCurrent('contact')">Kontak Kami</router-link>
                            </li>
                        </ul>
                        <a :href="frontend_url+'#/signup'" class="btn btn-primary mr-3 mr-md-0 rounded">Daftar Sekarang</a>&nbsp;
                        <div class="dropdown mobile-only" id="showNav">
                            <button class="btn btn-white px-3 show-mobile">
                                <span class="fe fe-menu"></span>
                            </button>
                            <button class="btn btn-white px-3 close-nav d-none">
                                <span class="fe fe-x"></span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Responsive Navigation -->
        <div class="section section-lg h-100 d-none" id="showMobile">
            <div class="container">
                <!-- content -->
                <div class="mt-5">
                    <div class="card box-mobile">
                        <div class="card-body">
                            <router-link :to="{ name : 'home'}" class="nav-link mb-2 close-link">
                                <a href="javascript:void(0)">Beranda <span class="fe fe-airplay float-right"></span></a>
                            </router-link>
                        </div>
                    </div>

                    <div class="card box-mobile">
                        <div class="card-body">
                            <router-link :to="{ name : 'about'}" class="nav-link mb-2 close-link">
                                <a href="javascript:void(0)">Tentang Kami <span class="fe fe-heart float-right"></span></a>
                            </router-link>
                        </div>
                    </div>

                    <div class="card box-mobile">
                        <div class="card-body">
                            <router-link :to="{ name : 'contact'}" class="nav-link mb-2 close-link">
                                <a href="javascript:void(0)">Kontak Kami <span class="fe fe-phone float-right"></span></a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <slot/>
        </div>

        <div class="d-block py-3 border-top footer" id="hideFooter">
            <div class="container">
                <div class="row col-md-12">
                    <div class="d-block h4 col-md-6 text-center-sm text-left">
                        Copyright © 2020 — 2022 <router-link :to="{ name : 'home' }" class="text-primary close-link" @click.native="setCurrent('home')">Gubuk Sains Prima</router-link>. All Rights Reserved.
                    </div>
                    <div class="d-block h4 col-md-6 text-center-sm text-right">
                        <router-link :to="{ name : 'term_conditions' }" class="text-primary">
                            Term Conditions
                        </router-link>
                        | 
                        <router-link :to="{ name : 'privacy_policy' }" class="text-primary">
                            Privacy Policy
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainMenu',
        data() {
            return {
                activeClass     : this.$route.name
            }
        },
        methods: {
            setCurrent: function(params = "") {
                this.activeClass    = params;
            }
        }
    }
</script>
