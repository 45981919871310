<template>
    <div>
        <main class="container mt-5">
            <article>
                <section>
                    <h1 class="text-xlg font-weight-bolder text-center-sm">Syarat dan ketentuan gsprima.com</h1>
                    <p class="mb-5">
                        Syarat dan ketentuan ini ("Perjanjian") menetapkan syarat dan ketentuan umum penggunaan Anda atas Aplikasi website gsprima.com dan Program layanan terkait. Perjanjian ini mengikat secara hukum antara Anda ("Pengguna", "Anda" atau "milik Anda") dan CV. GUBUK SAINS PRIMA ("CV. GUBUK SAINS PRIMA ", "kami", "kita " atau "milik kami"). Dengan mengakses dan menggunakan Aplikasi, Anda mengakui bahwa Anda telah membaca, memahami, dan setuju untuk terikat oleh ketentuan Perjanjian ini. Jika Anda menyetujui Perjanjian ini atas nama bisnis atau badan hukum lainnya, Anda menyatakan bahwa Anda memiliki kewenangan untuk mengikat entitas tersebut ke Perjanjian ini, dalam hal ini istilah "Pengguna", "Anda", atau "milik Anda" akan merujuk untuk entitas tersebut. Jika Anda tidak memiliki kewenangan tersebut, atau jika Anda tidak setuju dengan ketentuan Perjanjian ini, Anda tidak boleh menerima Perjanjian ini dan tidak boleh mengakses dan menggunakan Aplikasi. Anda mengakui bahwa Perjanjian ini adalah kontrak antara Anda dan CV. GUBUK SAINS PRIMA, meskipun ini elektronik dan tidak Anda tandatangani secara fisik, dan ini mengatur penggunaan Anda atas Aplikasi.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Akun dan keanggotaan</h2>
                    <p class="mb-4">
                        Anda harus berusia minimal 12 tahun untuk menggunakan Aplikasi dan Program layanan yang disediakan. Dengan menggunakan Aplikasi dan Program layanan dan dengan menyetujui Perjanjian ini, Anda menyatakan bahwa Anda setidaknya berusia minimal 12 tahun. Jika Anda membuat akun di Aplikasi, Anda bertanggung jawab untuk menjaga keamanan akun Anda dan Anda bertanggung jawab penuh atas semua aktivitas yang terjadi di bawah akun tersebut dan tindakan lain yang diambil sehubungan dengan itu. Kami mungkin, tetapi tidak berkewajiban, memantau dan meninjau akun baru sebelum Anda masuk dan mulai menggunakan Layanan. Memberikan informasi kontak palsu dalam bentuk apa pun dapat mengakibatkan penghentian akun Anda. Anda harus segera memberi tahu kami tentang penggunaan akun Anda yang tidak sah atau pelanggaran keamanan lainnya. Kami tidak akan bertanggung jawab atas tindakan atau kelalaian apa pun yang Anda lakukan, termasuk kerusakan apa pun yang timbul sebagai akibat dari tindakan atau kelalaian tersebut. Kami dapat menangguhkan, menonaktifkan, atau menghapus akun Anda (atau bagian mana pun darinya) jika kami menetapkan bahwa Anda telah melanggar ketentuan apa pun dari Perjanjian ini atau bahwa perilaku atau konten Anda cenderung merusak reputasi dan niat baik kami. Jika kami menghapus akun Anda karena alasan di atas, Anda tidak dapat mendaftar ulang untuk Layanan kami. Kami dapat memblokir alamat email dan alamat protokol Internet Anda untuk mencegah pendaftaran lebih lanjut.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Penagihan dan pembayaran</h2>
                    <p class="mb-4">
                        Dalam penggunaan Aplikasi dan Program layanan yang disediakan, Anda diharuskan membayar biaya atau ongkos ke akun Anda sesuai dengan biaya paket program yang dipilih dan ketentuan penagihan yang berlaku pada saat biaya atau ongkos tersebut jatuh tempo dan harus segera diselesaikan (dibayar). Jika Layanan ditawarkan dengan basis uji coba gratis, pembayaran mungkin diperlukan setelah periode uji coba gratis berakhir, dan bukan saat Anda memasukkan detail penagihan (yang mungkin diperlukan sebelum dimulainya periode uji coba gratis). Pertukaran data sensitif dan pribadi terjadi melalui saluran komunikasi aman SSL dan dienkripsi serta dilindungi dengan tanda tangan digital, dan Aplikasi dan Layanan juga sesuai dengan standar kerentanan PCI untuk menciptakan lingkungan yang seaman mungkin bagi Pengguna. Pemindaian malware dilakukan secara teratur untuk keamanan dan perlindungan tambahan. Jika, menurut penilaian kami, pembelian Anda merupakan transaksi berisiko tinggi, kami akan meminta Anda untuk memberikan salinan identifikasi foto resmi yang dikeluarkan pemerintah kepada kami, dan salinan laporan mutasi bank terbaru untuk kartu kredit atau debit yang digunakan untuk pembelian. Kami berhak untuk mengubah produk dan harga produk setiap saat. Kami juga berhak menolak pesanan apa pun yang Anda lakukan dengan kami. Kami dapat, atas kebijakan kami sendiri, membatasi atau membatalkan jumlah yang dibeli per orang, per rumah tangga, atau per pesanan. Pembatasan ini dapat mencakup pesanan yang dilakukan oleh atau di bawah akun pelanggan yang sama, kartu kredit yang sama, dan / atau pesanan yang menggunakan alamat penagihan dan / atau pengiriman yang sama. Jika kami melakukan perubahan atau membatalkan pesanan, kami dapat mencoba memberi tahu Anda dengan menghubungi email dan / atau alamat penagihan / nomor telepon yang diberikan pada saat pemesanan dibuat.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Ketepatan Informasi</h2>
                    <p class="mb-4">
                        Dalam hal ketepatan informasi, mungkin ada informasi dalam Aplikasi dan Program layanan yang berisi kesalahan ketik, ketidakakuratan, atau kelalaian terkait dengan ketersediaan kelengkapan program, promosi, dan penawaran. Kami berhak untuk memperbaiki kesalahan, ketidakakuratan, atau kelalaian tersebut, dan untuk mengubah atau memperbarui informasi atau membatalkan pesanan jika ada informasi dalam Aplikasi atau Program layanan yang tidak akurat setiap saat tanpa pemberitahuan sebelumnya (termasuk setelah Anda mengirimkan pesanan Anda). Kami tidak berkewajiban untuk memperbarui, mengubah atau mengklarifikasi informasi dalam Aplikasi termasuk, tanpa batasan, informasi harga, kecuali sebagaimana diharuskan oleh hukum. Tidak ada pembaruan atau tanggal penyegaran tertentu yang diterapkan dalam Aplikasi harus dianggap sebagai indikasi bahwa semua informasi dalam Aplikasi atau Program layanan telah dimodifikasi atau diperbarui.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Jaminan waktu (Uptime Guarantee)</h2>
                    <p class="mb-4">
                        Kami menawarkan jaminan layanan waktu 99% dari waktu yang tersedia per bulan. Jaminan waktu layanan tidak berlaku untuk gangguan layanan yang disebabkan oleh: (1) pemeliharaan atau perbaikan terjadwal berkala yang mungkin kami lakukan dari waktu ke waktu; (2) gangguan yang disebabkan oleh Anda atau aktivitas Anda; (3) pemadaman yang tidak memengaruhi fungsionalitas Layanan inti; (4) penyebab di luar kendali kami atau yang tidak dapat diduga secara wajar; dan (5) pemadaman yang terkait dengan keandalan lingkungan pemrograman tertentu.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Tautan ke sumber Daya lainnya</h2>
                    <p class="mb-4">
                        Walaupun Aplikasi dan Program layanan dapat ditautkan ke sumber daya lain (seperti situs web, aplikasi seluler, dll.), Kami tidak, secara langsung atau tidak langsung, menyiratkan persetujuan, asosiasi, sponsor, dukungan, atau afiliasi apa pun dengan sumber daya tertaut apa pun, kecuali secara khusus dinyatakan di sini. Beberapa tautan dalam Aplikasi mungkin berupa "tautan afiliasi". Artinya jika Anda mengklik link dan membeli item, CV. GUBUK SAINS PRIMA akan menerima komisi afiliasi. Kami tidak bertanggung jawab untuk memeriksa atau mengevaluasi, dan kami tidak menjamin penawaran dari, bisnis atau individu mana pun, atau konten sumber daya mereka. Kami tidak bertanggung jawab atau berkewajiban atas tindakan, produk, layanan, dan konten pihak ketiga lainnya. Anda harus berhati-hati meninjau pernyataan hukum dan ketentuan lain penggunaan sumber daya apa pun yang Anda akses melalui tautan di Aplikasi dan Program Layanan. Penautan Anda ke sumber daya lain di luar situs adalah risiko Anda sendiri.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Perubahan dan Amandemen</h2>
                    <p class="mb-4">
                        Dalam melakukan perubahan (amandemen), Kami berhak untuk mengubah Perjanjian ini atau persyaratan yang berkaitan dengan Aplikasi dan Program Layanan kapan saja, efektif setelah memposting versi terbaru Perjanjian ini dalam Aplikasi. Jika kami melakukannya, kami akan mengirimkan email atau media lain untuk memberi tahu Anda. Penggunaan berkelanjutan dari Aplikasi dan Program Layanan setelah perubahan tersebut merupakan persetujuan Anda untuk perubahan tersebut.                        
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Penerimaan persyaratan ini</h2>
                    <p class="mb-4">
                        Anda mengakui bahwa Anda telah membaca Perjanjian ini dan menyetujui semua syarat dan ketentuannya. Dengan mengakses dan menggunakan Aplikasi dan Program Layanan, Anda telah setuju untuk terikat oleh Perjanjian ini. Jika Anda tidak setuju untuk mematuhi ketentuan Perjanjian ini, Anda tidak diizinkan untuk mengakses atau menggunakan Aplikasi dan Program Layanan yang disediakan.
                    </p>
                </section>
                <section>
                    <h2 class="text-xl font-weight-bolder text-center-sm">Menghubungi kami </h2>
                    <p class="mb-4">
                        Jika Anda ingin menghubungi kami untuk memahami lebih lanjut tentang Perjanjian ini atau ingin menghubungi kami mengenai masalah apa pun yang berkaitan dengannya, Anda dapat mengirim email ke cv.gubuksainsprima@gmail.com atau menulis surat dan mengirimkannya ke alamat Athaya Ville Blok A-15, Jalan Raya Puri, Kel. Puri, Kec. Puri, Kab. Mojokerto, Provinsi Jawa Timur, 61363, Indonesia.                        
                    </p>
                    <br/>
                </section>
            </article>
        </main>
    </div>
</template>

<script>
    export default {
        name: 'TermConditions',
        metaInfo: {
            title: 'Gubuk Sains Prima',
            titleTemplate: '%s — Syarat dan ketentuan'
        }
    }
</script>