<template>
    <div>
        <div class="" id="hideMobile">
            <div class="home-banner">
                <div class="glide">
                    <div class="glide__track" data-glide-el="track">
                        <ul class="glide__slides">
                            <li class="glide__slide"><img style="width:100%" src="assets/images/banner/slide-2.jpg"></li>
                            <li class="glide__slide"><img style="width:100%" src="assets/images/banner/slide-3.jpg"></li>
                            <li class="glide__slide"><img style="width:100%" src="assets/images/banner/slide-4.jpg"></li>
                            <li class="glide__slide"><img style="width:100%" src="assets/images/banner/welcome.png"></li>
                        </ul>
                    </div>

                    <div class="glide__bullets" data-glide-el="controls">
                        <button class="glide__bullet" data-glide-dir="=0"></button>
                        <button class="glide__bullet" data-glide-dir="=1"></button>
                        <button class="glide__bullet" data-glide-dir="=2"></button>
                        <button class="glide__bullet" data-glide-dir="=3"></button>
                    </div>
                </div>
            </div>

            <div style="margin-top:-15px !important">
                <div class="section section-lg card-style-top-right" data-wow-duration="1s"
                    style="background-image: url('assets/images/blank-background.png');background-size: cover;">
                    <div class="container-fluid">
                        <div class="row justify-content-center wow fadeInDown">
                            <div class="col-md-6 text-center col-sm-12">
                                <div class="title-lg font-weight-bolder mb-3">
                                    Yuk kenalan lebih jauh dengan<br />
                                    <span class="text-primary">Gubuk Sains Prima</span>
                                </div>
                                <p class="mb-4">Gubuk Sains Prima hadir untuk membantu kamu meningkatkan kualitas
                                    belajar
                                    dengan solusi terlengkap di era digital yang didukung<br/>oleh aplikasi digital yang
                                    canggih.</p>
                                    <a href="https://www.youtube.com/watch?v=QqWxiFsg4l0" data-lity>
                                    <div class="intro-box shadow"
                                        style="background-image: url('assets/images/cover-intro.jpg');"><span
                                            class="fe fe-play-circle m-0"></span></div>
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-wow-duration="1s" class="d-block bg-primary wow fadeInUp">
                <div class="py-4">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-8 mb-md-0 mb-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="h2 m-0 text-white">Ayo gabung bersama kami</div>
                                        <div class="d-block text-white text-sm m-0">Gabung dan menjadi bagian dari
                                            kami untuk pengalaman belajar yang lebih menarik! </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-right"><a :href="frontend_url+'#/signup'"
                                    class="btn btn-white shadow rounded px-4">Daftar
                                    Sekarang</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="section section-lg wow fadeInLeft" data-wow-duration="1s">
                    <div class="container">
                        <div class="h1 d-block mb-4 pb-3 border-bottom font-weight-bold">Belajar Khusus
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 mb-md-4 mb-3">
                                <div class="card border lift mb-0 h-100" style="overflow: hidden;"><img
                                        src="assets/images/cover/cpns.jpg" class="w-100 border-bottom">
                                    <div class="card-body">
                                        <h2 class="mb-2">Program CPNS</h2>
                                        <p class="text-sm text-truncate-2">
                                            Singkatan dari Calon Pegawai Negeri Sipil. Merupakan program belajar
                                            yang intensif sebagai persiapan peserta dalam menghadapi ujian CPNS tahun
                                            2022. Program ini menawarkan platform persiapan
                                        </p>
                                    </div>
                                    <div class="card-footer card-min-35">
                                        <div class="d-block">
                                            <a :href="frontend_url+'#/signin'" class="btn btn-primary btn-block">Ke
                                                Aplikasi</a>

                                            <router-link :to="{ name : 'program', params : { code : 'cpns'}}"
                                                class="btn btn-outline-primary btn-block">Lihat
                                                Program</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 mb-md-4 mb-3">
                                <div class="card border lift mb-0 h-100" style="overflow: hidden;"><img
                                        src="assets/images/cover/kedinasan.jpg" class="w-100 border-bottom">
                                    <div class="card-body">
                                        <h2 class="mb-2">Program Kedinasan</h2>
                                        <p class="text-sm text-truncate-2">
                                            Merupakan program belajar khusus dan fokus bagi para peserta
                                            yang akan mengikut seleksi masuk Sekolah Kedinasan seperti STAN, STIS, STIN,
                                            STMKG, SSN, IPDN,
                                        </p>
                                    </div>
                                    <div class="card-footer card-min-35">
                                        <div class="d-block">
                                            <a :href="frontend_url+'#/signin'" class="btn btn-primary btn-block">Ke
                                                Aplikasi</a>

                                            <router-link :to="{ name : 'program', params : { code : 'kedinasan'}}"
                                                class="btn btn-outline-primary btn-block">Lihat
                                                Program</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 mb-md-4 mb-3">
                                <div class="card border lift mb-0 h-100" style="overflow: hidden;"><img
                                        src="assets/images/cover/pppk.jpg" class="w-100 border-bottom">
                                    <div class="card-body">
                                        <h2 class="mb-2">Program PPPK</h2>
                                        <p class="text-sm text-truncate-2">
                                            Singkatan dari Pegawai Pemerintah dengan Perjanjian Kerja. Merupakan
                                            program belajar yang komprehensif untuk memastikan setiap peserta mampu
                                            mengerti dan memahami
                                        </p>
                                    </div>
                                    <div class="card-footer card-min-35">
                                        <div class="d-block">
                                            <a :href="frontend_url+'#/signin'" class="btn btn-primary btn-block">Ke
                                                Aplikasi</a>

                                            <router-link :to="{ name : 'program', params : { code : 'pppk'}}"
                                                class="btn btn-outline-primary btn-block">Lihat
                                                Program</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-min-70">
                <div class="section section-lg wow fadeInRight" data-wow-duration="1s">
                    <div class="container">
                        <div class="h1 d-block mb-4 pb-3 border-bottom font-weight-bold">Kompetisi Online (Pelajar
                            Olimpiade)
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 mb-md-4 mb-3">
                                <div class="card border lift mb-0 h-100" style="overflow: hidden;"><img
                                        src="assets/images/cover/olimpiade.png" class="w-100 border-bottom">
                                    <div class="card-body">
                                        <h2 class="mb-2">Olimpiade</h2>
                                        <p class="text-sm text-truncate-2">
                                            Merupakan kompetisi online yang bekerjasama dengan Pelajar
                                            Olimpiade dalam menyelenggarakan kegiatan Olimpiade tingkat nasional dan
                                            telah berjalan sejak tahun 2019.
                                        </p>
                                    </div>
                                    <div class="card-footer card-min-35">
                                        <div class="d-block">
                                            <a :href="frontend_url+'#/signin'" class="btn btn-primary btn-block">Ke
                                                Aplikasi</a>

                                            <router-link :to="{ name : 'program', params : { code : 'olimpiade'}}"
                                                class="btn btn-outline-primary btn-block">Lihat
                                                Program</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-md-4 mb-3">
                                <div class="card border lift mb-0 h-100" style="overflow: hidden;"><img
                                        src="assets/images/cover/esai.png" class="w-100 border-bottom">
                                    <div class="card-body">
                                        <h2 class="mb-2">Esai</h2>
                                        <p class="text-sm text-truncate-2">
                                            Merupakan kompetisi menulis individu (bekerjasama dengan Pelajar
                                            Olimpiade) yang membahas suatu masalah atau isu yang sedang hangat di
                                            tengah masyarakat.
                                        </p>
                                    </div>
                                    <div class="card-footer card-min-35">
                                        <div class="d-block">
                                            <a :href="frontend_url+'#/signin'" class="btn btn-primary btn-block">Ke
                                                Aplikasi</a>

                                            <router-link :to="{ name : 'program', params : { code : 'esai'}}"
                                                class="btn btn-outline-primary btn-block">Lihat
                                                Program</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-md-4 mb-3">
                                <div class="card border lift mb-0 h-100" style="overflow: hidden;"><img
                                        src="assets/images/cover/lkti.png" class="w-100 border-bottom">
                                    <div class="card-body">
                                        <h2 class="mb-2">LKTI</h2>
                                        <p class="text-sm text-truncate-2">
                                            Singkatan dari Lomba Karya Tulis Ilmiah. Merupakan Lomba menulis
                                            karya tulis ilmiah tingkat nasional per kelompok (bekerjasama dengan Pelajar
                                            Olimpiade) yang dapat diikuti oleh peserta
                                        </p>
                                    </div>
                                    <div class="card-footer card-min-35">
                                        <div class="d-block">
                                            <a :href="frontend_url+'#/signin'" class="btn btn-primary btn-block">Ke
                                                Aplikasi</a>

                                            <router-link :to="{ name : 'program', params : { code : 'lkti'}}"
                                                class="btn btn-outline-primary btn-block">Lihat
                                                Program</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Home',
        metaInfo: {
            title: 'Gubuk Sains Prima',
            titleTemplate: '%s — Beranda'
        },
        mounted() {
            this.getGlide();
        },
        methods: {
            getGlide: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom-glide.js')
                document.head.appendChild(externalScript)
            }
        }
    }
</script>