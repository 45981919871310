<template>
    <div>
        <div class="section section-xl card-style-bottom-left card-3d-bottom bg-lighter bg-image"
            style="background-image: url('assets/images/blank-background.png');">
            <div class="container">
                <div class="d-flex align-items-center justify-content-center mt-3">
                    <img src="assets/images/maintenance.svg" class="mx-2 maintenance-img">
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-md-6 text-center">
                        <h1 class="font-weight-boldermb-3 mt-3">Halaman sedang dalam perbaikan</h1>
                        <p>
                            Mohon maaf halaman ini sedang dalam perbaikan.<br/>
                            Apabila kamu butuh bantuan silakan hubungi kami.
                        </p>
                        <router-link :to="{ name : 'home'}" class="btn btn-primary btn-block">Kembali ke halaman utama</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
        metaInfo: {
            title: 'Gubuk Sains Prima',
            titleTemplate: '%s — Maintenance'
        },
    }
</script>